import ReactDOM from 'react-dom';
import React from 'react';
import InvestmentCalculator from './InvestmentCalculator';
import './frontend.css';

function renderCalculatorComponent(block, config) {
  const container = block.querySelector('.mm_calculator_sip_lumpsum_aio_block');
  if (container) {
    ReactDOM.render(<InvestmentCalculator config={config} />, container);
  } else {
    console.error('Container element not found');
  }
}

function renderCalculatorComponents() {
  const blocks = document.querySelectorAll('section[id^="mm_calculator_sip_lumpsum_aio_block_"]');
  blocks.forEach(block => {
    const config = block.getAttribute('data-config');
    if (config) {
      const parsedConfig = JSON.parse(config);
      renderCalculatorComponent(block, parsedConfig);
    } else {
      console.error('Configuration not found');
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  renderCalculatorComponents();
});

export { renderCalculatorComponents, renderCalculatorComponent };
