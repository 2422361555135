import React, { useState } from 'react';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'react-toggle/style.css';
import './frontend.css';

const InvestmentCalculator = ({ config }) => {
  const [sipAmount, setSipAmount] = useState(parseInt(config.sipAmount));
  const [sipTopUpPercent, setSipTopUpPercent] = useState(parseFloat(config.sipTopUpPercent));
  const [maxSipAmount, setMaxSipAmount] = useState(parseInt(config.maxSipAmount));
  const [years, setYears] = useState(parseInt(config.years));
  const [holdYears, setHoldYears] = useState(parseInt(config.holdYears));
  const [expectedReturn, setExpectedReturn] = useState(parseFloat(config.expectedReturn));
  const [lumpSumAmount, setLumpSumAmount] = useState(parseInt(config.lumpSumAmount));
  const [expectedLumpSumReturn, setExpectedLumpSumReturn] = useState(parseFloat(config.expectedLumpSumReturn));

  const handleSipAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    setSipAmount(parseFloat(value) || 0);
  };

  const handleSipTopUpPercentChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    setSipTopUpPercent(parseFloat(value) || 0);
  };

  const handleMaxSipAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    setMaxSipAmount(parseFloat(value) || 0);
  };

  const handleYearsChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (value > 100) {
      value = 100;
    }
    setYears(parseFloat(value) || 0);
  };

  const handleHoldYearsChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (value > 50) {
      value = 50;
    }
    setHoldYears(parseFloat(value) || 0);
  };

  const handleExpectedReturnChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (value > 100) {
      value = 100;
    }
    setExpectedReturn(parseFloat(value) || 0);
  };

  const handleLumpSumAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    setLumpSumAmount(parseFloat(value) || 0);
  };

  const handleExpectedLumpSumReturnChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (value > 100) {
      return value;
    }
    setExpectedLumpSumReturn(parseFloat(value) || 0);
  };

  const calculateResults = () => {
    const results = [];
    let totalSipTopup = 0;
    let totalSip = 0;
    let currentSipAmount = sipAmount;

    for (let year = 1; year <= years + holdYears; year++) {
      let previousWealth = results.length ? parseFloat(results[results.length - 1].wealth) : 0;
      let previousWealthFixedSip = results.length ? parseFloat(results[results.length - 1].wealthFixedSip) : 0;
      let prevLumpSumWealth = results.length ? parseFloat(results[results.length - 1].lumpSumWealth) : lumpSumAmount;
      const monthlyRate = Math.pow(1 + expectedReturn / 100, 1 / 12) - 1;
      const monthlyLumpSumpRate = Math.pow(1 + expectedLumpSumReturn / 100, 1 / 12) - 1;

      
      let fixedSip = sipAmount;

      if (year > 1) {
        currentSipAmount *= (1 + sipTopUpPercent / 100);
      }
      if (currentSipAmount > maxSipAmount) {
        currentSipAmount = maxSipAmount;
      }
      if (year > years) {
        currentSipAmount = 0;
        fixedSip = 0;
      }
      
      let wealthEndOfYear = 0;
      let wealthEndOfYearFixedSip = 0;
      let limpSumpWealthEndOfYear = 0;
      for (let month = 1; month <= 12; month++) {
        wealthEndOfYear = currentSipAmount * (1 + monthlyRate) + previousWealth * (1 + monthlyRate);
        previousWealth = wealthEndOfYear;
        totalSipTopup = totalSipTopup + currentSipAmount;
      }
      for (let month = 1; month <= 12; month++) {
        wealthEndOfYearFixedSip = fixedSip * (1 + monthlyRate) + previousWealthFixedSip * (1 + monthlyRate);
        previousWealthFixedSip = wealthEndOfYearFixedSip;
        totalSip = totalSip + fixedSip;
      }
      for (let month = 1; month <= 12; month++) {
        limpSumpWealthEndOfYear = prevLumpSumWealth * (1 + monthlyLumpSumpRate);
        prevLumpSumWealth = limpSumpWealthEndOfYear;
      }
      results.push({
        year,
        totalSip,
        totalSipTopup,
        sipAmount: currentSipAmount,
        wealth: wealthEndOfYear.toFixed(2),
        wealthFixedSip: wealthEndOfYearFixedSip.toFixed(2),
        lumpSumWealth: limpSumpWealthEndOfYear.toFixed(2),
      });
    }

    return results;
  };

  const results = calculateResults();

  const calculateSummary = () => {
    if (results.length === 0) return {};

    const totalInvestmentA = parseFloat(results[years + holdYears - 1]?.totalSipTopup ?? 0);
    const expectedFutureValueA = parseFloat(results[years + holdYears - 1]?.wealth ?? 0);

    const totalInvestmentB = years * 12 * sipAmount;
    const monthlyRate = Math.pow(1 + expectedReturn / 100, 1 / 12) - 1;
    const expectedFutureValueB = parseFloat(results[years + holdYears - 1]?.wealthFixedSip ?? 0);

    const differenceAminusB = totalInvestmentA - totalInvestmentB;
    const differenceFutureValueAminusB = expectedFutureValueA - expectedFutureValueB;

    const lumpSumC = lumpSumAmount;
    const lumpSumFutureValue = parseFloat(results[years + holdYears - 1]?.lumpSumWealth ?? 0);;

    const lumpSumPlusSIPA = totalInvestmentA + lumpSumC;
    const lumpSumPlusFutureValueA = expectedFutureValueA + lumpSumFutureValue;

    const lumpSumPlusSIPB = totalInvestmentB + lumpSumC;
    const lumpSumPlusFutureValueB = expectedFutureValueB + lumpSumFutureValue;

    return {
      totalInvestmentA,
      expectedFutureValueA,
      totalInvestmentB,
      expectedFutureValueB,
      differenceAminusB,
      differenceFutureValueAminusB,
      lumpSumC,
      lumpSumFutureValue,
      lumpSumPlusSIPA,
      lumpSumPlusFutureValueA,
      lumpSumPlusSIPB,
      lumpSumPlusFutureValueB,
    };
  };

  const summary = calculateSummary();

  return (
    <div className="investment-calculator-container">
      <div className="row">
        <h4>{config.title}</h4>
      </div>
      <div className="row">
        <div className="col-md-6">
          <form>
            <div>
              <label>SIP amount (per month):</label>
              <input
                type="text"
                value={formatInputValue(sipAmount)}
                onChange={handleSipAmountChange}
              />
            </div>
            <div>
              <label className='table-alt-bg'>SIP top-up % (after every 12 months):</label>
              <input
                type="text"
                value={formatInputValue(sipTopUpPercent)}
                onChange={handleSipTopUpPercentChange}
              />
            </div>
            <div>
              <label>Max SIP amount cap:</label>
              <input
                type="text"
                value={formatInputValue(maxSipAmount)}
                onChange={handleMaxSipAmountChange}
              />
            </div>
            <div>
              <label className='table-alt-bg'>Fresh SIP will continue for (no. of years):</label>
              <input
                type="text"
                value={formatInputValue(years)}
                onChange={handleYearsChange}
              />
            </div>
            <div>
              <label>Thereafter investment will be held for (no. of years):</label>
              <input
                type="text"
                value={formatInputValue(holdYears)}
                onChange={handleHoldYearsChange}
              />
            </div>
            <div>
              <label className='table-alt-bg'>Expected return from SIP (p.a.):</label>
              <input
                type="text"
                value={formatInputValue(expectedReturn)}
                onChange={handleExpectedReturnChange}
              />
            </div>
            <div>
              <label>Lump sum investment amount (if any):</label>
              <input
                type="text"
                value={formatInputValue(lumpSumAmount)}
                onChange={handleLumpSumAmountChange}
              />
            </div>
            <div className='bb'>
              <label className='table-alt-bg'>Expected return from lump sum (p.a.):</label>
              <input
                type="text"
                value={formatInputValue(expectedLumpSumReturn)}
                onChange={handleExpectedLumpSumReturnChange}
              />
            </div>
          </form>
          <div className="row mt33">
            <div className="col-md-12 table-container">
              <SummaryTable summary={summary} />
            </div>
          </div>
        </div>
        <div className="col-md-6 table-container">
          <ResultsTable results={results} />
        </div>
      </div>
    </div>
  );
};

const formatNumber = (num) => {
  if (!num || num <= 0) {
    return "-";
  }
  num = parseInt(num);
  return new Intl.NumberFormat('en-IN').format(num);
};

const formatInputValue = (value) => {
  if (!value) return '';
  return new Intl.NumberFormat('en-IN').format(value);
};

const ResultsTable = ({ results }) => {
  return (
    <table>
      <thead>
        <tr className='bg-orange'>
          <th className='align-center bg-orange head'>Year</th>
          <th className='align-center bg-orange head'>Monthly SIP Amount</th>
          <th className='align-center bg-orange head'>Wealth at End of Year</th>
        </tr>
      </thead>
      <tbody>
        {results.map(result => (
          <tr key={result.year}>
            <td className='align-center'>{result.year}</td>
            <td className='align-right'>{formatNumber(result.sipAmount)}</td>
            <td className='align-right'>{formatNumber(result.wealth)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const SummaryTable = ({ summary }) => {
  return (
    <table>
      <thead>
        <tr>
          <th className='head'></th>
          <th className='bg-blue align-center head'>Total Investment</th>
          <th className='bg-blue align-center head'>Expected Future Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='bg-blue'>TOP-UP SIP (A)</td>
          <td className='align-right'>{formatNumber(summary.totalInvestmentA)}</td>
          <td className='align-right'>{formatNumber(summary.expectedFutureValueA)}</td>
        </tr>
        <tr>
          <td className='bg-blue'>FIXED SIP (B)</td>
          <td className='align-right'>{formatNumber(summary.totalInvestmentB)}</td>
          <td className='align-right'>{formatNumber(summary.expectedFutureValueB)}</td>
        </tr>
        <tr className='bg-orange'>
          <td>DIFFERENCE (A - B)</td>
          <td className='align-right'>{formatNumber(summary.differenceAminusB)}</td>
          <td className='align-right'>{formatNumber(summary.differenceFutureValueAminusB)}</td>
        </tr>
        <tr>
          <td className='bg-blue'>LUMP SUM (C)</td>
          <td className='align-right'>{formatNumber(summary.lumpSumC)}</td>
          <td className='align-right'>{formatNumber(summary.lumpSumFutureValue)}</td>
        </tr>
        <tr className='bg-orange'>
          <td>LUMP SUM + SIP (A)</td>
          <td className='align-right'>{formatNumber(summary.lumpSumPlusSIPA)}</td>
          <td className='align-right'>{formatNumber(summary.lumpSumPlusFutureValueA)}</td>
        </tr>
        <tr className='bg-orange'>
          <td className='bg-orange'>LUMP SUM + SIP (B)</td>
          <td className='bg-orange align-right'>{formatNumber(summary.lumpSumPlusSIPB)}</td>
          <td className='bg-orange align-right'>{formatNumber(summary.lumpSumPlusFutureValueB)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvestmentCalculator;
